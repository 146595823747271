<template>
    <div class="categoria">
        <div class>
            <div class="uk-container uk-container-large">
                <h1 style="color:#000357;">{{ category.name }}</h1>

                <ArticlesList :articles="category.articles || []"></ArticlesList>
            </div>
        </div>
    </div>
</template>

<script>
import ArticlesList from "../components/ArticleList2";
import axios from "axios";
export default {
    data() {
        return {
            category: [],
        };
    },
    components: {
        ArticlesList,
    },
    async beforeCreate() {
        const response = await axios.get(
            `https://backblog.intermax.pe/categories?slug=${this.$route.params.id}`
        );
        this.category = response.data[0];
    },
};
</script>

<style scoped>
</style>
