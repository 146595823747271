<template>
    <nav
        class="navbar navbar-expand-md navbar-light"
        aria-label="Fourth navbar example"
        v-if="ready"
        style="background-color:transparent; "
    >
        <div class="container-fluid d-flex align-items-center">
            <a class="navbar-brand" href="/" style="color:white">
                <img src="@/assets/logo2.png" style="width:200px" alt />
            </a>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarsExample04"
                aria-controls="navbarsExample04"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarsExample04">
                <ul class="navbar-nav w-100 me-auto mb-2 mb-md-0 d-flex justify-content-end">
                    <li v-for="category in categories" v-bind:key="category.id" class="nav-item">
                        <router-link
                            class="nav-link"
                            style="color:#000357; font-weight: bold"
                            :to="{ path: '/servicios/' + category.slug }"
                            :key="category.id"
                        >{{ category.name }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<style scoped>
.navbar {
    padding: 2rem 5%;
}
</style>

<script>
import axios from "axios";

export default {
    name: "Nav",
    data() {
        return {
            categories: [],
            ready: false,
        };
    },
    async beforeCreate() {
        const response = await axios.get(
            "https://backblog.intermax.pe/categories"
        );
        this.categories = response.data;
        this.ready = true;
    },
};
</script>
