<template>
    <div>
        <div class="uk-section" v-if="ready">
            <div class="uk-container uk-container-large">
                <h1 style="color:#000357">Intermax Blog</h1>

                <ArticlesList :articles="articles"></ArticlesList>
            </div>
        </div>
    </div>
</template>

<script>
import ArticlesList from "../components/ArticlesList.vue";
import axios from "axios";

export default {
    components: {
        ArticlesList,
    },
    data() {
        return {
            articles: [],
            ready: false,
        };
    },
    async beforeCreate() {
        const response = await axios.get(
            "https://backblog.intermax.pe/articles"
        );
        this.articles = response.data;
        this.ready = true;
    },
};
</script>
