<template>
  <div id="app">
    <Nav />
    <router-view :key="$route.fullPath"></router-view>
    <Footer />
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: { Nav ,Footer}
};
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

#app{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}
a {
  text-decoration: none;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 4rem;
}

#category {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

#title {
  letter-spacing: 0.4px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.13636;
}

#banner {
  margin: 20px;
  height: 800px;
}

#editor {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.uk-navbar-container {
  background: #fff !important;
  font-family: 'Roboto', sans-serif;
}

a{
  text-decoration: none !important;
}

</style>
