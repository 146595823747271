<template>
    <div>
        <div class="d-flex contenedor-principal">
            <div>
                <router-link
                    v-for="article in leftArticles"
                    :to="{ path: '/article/' + article.slug }"
                    class="uk-link-reset m-2"
                    :key="article.id"
                >
                    <div class="uk-card uk-card-muted deri-articulo sup">
                        <div class="uk-card-media-top">
                            <img
                                :src="article.image.url"
                                v-if="state === 'production'"
                                alt
                                style="border-radius:1rem;"
                            />
                            <img
                                :src="api_url + article.image.url"
                                v-else
                                alt
                                style="border-radius:1rem;"
                            />
                        </div>
                        <div class="uk-card-body">
                            <p
                                id="category"
                                v-if="article.category"
                                class="uk-text-uppercase"
                                style="color:#1793d2"
                            >{{ article.category.name }}</p>
                            <p
                                id="title"
                                class="uk-text-large"
                                style="font-weight:bold; color: #4d4d4d"
                            >{{ article.title }}</p>
                            <p>{{article.description}}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div>
                <div class="row justify-content-center">
                    <router-link
                        v-for="article in rightArticles"
                        :to="{ path: '/article/' + article.slug }"
                        class="col-sm-5"
                        :key="article.id"
                    >
                        <div class="deri-articulo">
                            <div
                                v-if="article.image"
                                class="uk-height-small uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding"
                                style="height:200px; border-radius:1rem"
                                :data-src="api_url + article.image.url"
                                uk-img
                            ></div>
                            <div class="p-3">
                                <p
                                    id="category"
                                    v-if="article.category"
                                    class="uk-text-uppercase"
                                    style="color:#1793d2"
                                >{{ article.category.name }}</p>
                                <p
                                    id="title"
                                    class="uk-text-large"
                                    style="font-weight:bold; color: #4d4d4d"
                                >{{ article.title }}</p>
                                <p style="color:black; text-align:justify">{{article.description}}</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.deri-articulo .uk-text-large {
    transition: 0.3s ease-in-out;
}

.deri-articulo:hover .uk-text-large {
    color: #003576 !important;
}
@media screen and (max-width: 992px) {
    .contenedor-principal {
        flex-direction: column;
    }
    .sup{
        display: flex;
        
    }

    .sup .uk-card-media-top , .sup .uk-card-body {
        width : 50%;
    }
}

@media screen and (max-width: 575px){
    .sup{
        flex-direction: column;
        
    }

    .sup .uk-card-media-top , .sup .uk-card-body {
        width : 100%;
    }
}
</style>

<script>
export default {
    data: function () {
        return {
            api_url:
                process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
            state: process.env.NODE_ENV,
        };
    },
    props: {
        articles: Array,
    },
    computed: {
        leftArticlesCount() {
            return Math.ceil(this.articles.length / 5);
        },
        leftArticles() {
            return this.articles.slice(0, this.leftArticlesCount);
        },
        rightArticles() {
            return this.articles.slice(
                this.leftArticlesCount,
                this.articles.length
            );
        },
    },
};
</script>
