<template>
    <div v-if="ready" class="d-flex flex-column justify-content-center" style="text-align: center">
        <h1 style="margin-top:2rem">{{ article.title }}</h1>

        <div class="container-fluid d-flex justify-content-center">
            <div
                v-if="article.image"
                id="banner"
                class="uk-height-small uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding"
                style="width :1500px; height:500px; border-radius:1rem"
                :data-src="api_url + article.image.url"
                uk-img
            ></div>
        </div>

        <div class="uk-section">
            <div class="uk-container uk-container-large d-flex">
                <p
                    v-if="article.published_at"
                    class="w-50"
                >{{ moment(article.published_at).format("MMM Do YY") }}</p>
                <vue-markdown-it
                    v-if="article.content"
                    :source="article.content"
                    id="editor"
                    style="text-align:justify;"
                />
            </div>
        </div>
    </div>
</template>

<script>
var moment = require("moment");
import VueMarkdownIt from "vue-markdown-it";
import axios from "axios";
export default {
    data() {
        return {
            article: {},
            ready: false,
            moment: moment,
            api_url:
                process.env.VUE_APP_STRAPI_API_URL ||
                "http://https://backblog.intermax.pe",
        };
    },
    components: {
        VueMarkdownIt,
    },
    async beforeCreate() {
        try {
            const respond = await axios.get(
                `https://backblog.intermax.pe/articles?slug=${this.$route.params.id}`
            );
            this.article = respond.data[0];
            this.ready = true;
        } catch (e) {
            alert(e);
        }
    },
};
</script>
