<template>
    <!-- Footer -->
    <footer
        class="text-center text-muted d-flex justify-content-between footer"
        style="background-color: rgba(0, 0, 0, 0.8);"
    >
        <!-- Copyright -->
        <div class="text-center p-4">
            <img src="@/assets/logo.png" width="250" alt />
        </div>
        <div class="text-center copy" style="color:white">© 2021 Copyright - Intermax</div>
        <div class="text-center p-4" style="color:white">
            <i class="fab fa-linkedin fa-2x m-3"></i>
        </div>
        <!-- Copyright -->
    </footer>
    <!-- Footer -->
</template>

<script>
export default {
    setup() {
        return {};
    },
};
</script>

<style scoped>
.copy {
    padding: 2rem;
    display: flex;
    align-items: center;
}
@media screen and (max-width:750px) {
    footer{
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}
</style>